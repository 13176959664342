import React from "react"
import { graphql } from "gatsby"
import { Layout, Seo, Container, RoundedBox } from "../../components"
import * as styles from './style.module.scss'


const AppConfirmationTemplate = ({ data, pageContext }) => {
  const confirmation = data.confirmation.appReturnFields.appReturnFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

  return (
    <Layout>
      <Seo title={confirmation.heading} />
      
			<RoundedBox
				className={styles.roundedBox}
				transform="rotate(26deg)"
				width="916px"
				height="996px"
				position={{
					top: "-800px",
					right: "100px"
				}}
			/>

			<Container>
				<div
					className={styles.content}
					dangerouslySetInnerHTML={{__html: confirmation.copy}}
				></div>
			</Container>
    </Layout>
  )
}

export const Query = graphql`query AppConfirmationTemplate {
  confirmation: wpPage(slug: {eq: "app-confirmation"}) {
    id
    appReturnFields {
      appReturnFields {
        languageValue {
          slug
          name
        }
        heading
        copy
      }
    }
  }
}
`

export default AppConfirmationTemplate
